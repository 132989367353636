import autoComplete from '@tarekraafat/autocomplete.js';

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.autoComplete').forEach(function (el){
    new Autocomplete(el.id);
  });

  var min_horizontal_move = 60;
  var max_vertical_move = 60;
  var within_ms = 1000;

  var start_xPos;
  var start_yPos;
  var start_time;
  function touch_start(event) {
    start_xPos = event.touches[0].pageX;
    start_yPos = event.touches[0].pageY;
    start_time = new Date();
  }

  function touch_end(event) {
    var end_xPos = event.changedTouches[0].pageX;
    var end_yPos = event.changedTouches[0].pageY;
    var end_time = new Date();
    let move_x = end_xPos - start_xPos;
    let move_y = end_yPos - start_yPos;
    let elapsed_time = end_time - start_time;
    if (Math.abs(move_x) > min_horizontal_move && Math.abs(move_y) < max_vertical_move && elapsed_time < within_ms) {
      if (move_x < 0) {
        document.body.classList.remove('sidebar_closed');
      } else {
        document.body.classList.add('sidebar_closed');
      }
    }
  }

  var content = document.getElementById("body");
  content.addEventListener('touchstart', touch_start);
  content.addEventListener('touchend', touch_end);
});

class Autocomplete {
  constructor (formId) {
    let formSelector = `#${formId}`;
    let formList = `${formId}_list`;
    let formListSelector = `#${formList}`;

    new autoComplete({
      data: {                              // Data src [Array, Function, Async] | (REQUIRED)
        src: async () => {
          let val = document.getElementById(formId).value;
          let voc = document.getElementById(formId).dataset.voc || '';
          // Fetch External Data Source
          const source = await fetch(`/words/autocomplete.json?l=${val}&v=${voc}`);
          // Format data into JSON
          const data = await source.json();
          // Return Fetched data
          return data;
        },
        key: ["value"],
        cache: false
      },
      sort: function (a, b) {
        if (a.match < b.match) {
          return -1;
        }
        if (a.match > b.match) {
          return 1;
        }
        return 0;
      },
      query: {
        manipulate: function (query) {
          return query.normalize("NFC");
        },
      },
      trigger: {
        event: ["input", "focusin", "focusout"],
        condition: function (query) {
          return !!query.replace(/ /g, "").length;
        },
      },
      placeHolder: "быстрый поиск ",
      selector: formSelector,
      debounce: 0,
      searchEngine: "strict",
      highlight: true,
      maxResults: 10,
      resultsList: {
        render: true,
        container: function (source) {
          source.setAttribute("id", formList);
        },
        element: "ul",
        destination: document.querySelector(formSelector),
        position: "afterend",
      },
      resultItem: {
        content: function (data, source) {
          source.innerHTML = data.match;
        },
        element: "li",
      },
      noResults: function () {
        const result = document.createElement("li");
        result.setAttribute("class", "no_result");
        result.setAttribute("tabindex", "1");
        result.innerHTML = "Ничего не найдено";
        document.querySelector(formListSelector).appendChild(result);
      },
      onSelection: function (feedback) {
        document.querySelector(formSelector).blur();
        // Render selected choice to selection div
        // document.querySelector(".selection").innerHTML = selection;
        // Clear Input
        document.querySelector(formSelector).value = "";
        // Change placeholder with the selected value
        // document.querySelector(formSelector).setAttribute("placeholder", selection);
        window.location = feedback.selection.value.url;
      },
    });

    // Toggle event for search input
    // showing & hidding results list onfocus / blur
    ["focus", "blur"].forEach(function (eventType) {
      const resultsList = document.querySelector(formListSelector);

      document.querySelector(formSelector).addEventListener(eventType, function () {
        // Hide results list & show other elemennts
        if (eventType === "blur") {
          resultsList.style.display = "none";
        } else if (eventType === "focus") {
          // Show results list & hide other elemennts
          resultsList.style.display = "block";
        }
      });
    });
  }
}
